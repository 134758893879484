.montserrat {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.montserrat-semi-bold {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}

.montserrat-bold {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'NeuzeitGro';
    src: url('../assets/fonts/NeuzeitGro-Reg.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'NeuzeitGro';
    src: url('../assets/fonts/NeuzeitGro-Bol.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

.neuzeit-gro {
    font-family: "NeuzeitGro", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.neuzeit-gro-semi-bold {
    font-family: "NeuzeitGro", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}

.neuzeit-gro-bold {
    font-family: "NeuzeitGro", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
}
