$colour-light-blue: rgb(0, 140, 255);
$colour-firesmart-yellow: rgb(239, 202, 0);
$colour-firesmart-green: rgb(57, 179, 74);
$colour-light-bluewhite: rgb(248, 250, 254);
$colour-lighter-bluewhite: hsl(220, 70%, 97%);
$colour-darker-bluewhite: hsl(240, 71%, 85%);
$colour-lightest-bluewhite: hsl(220, 95%, 99%);
$colour-dark-grey: #282c34;
$colour-primary-dark: #212a34;
$colour-red: hsl(0, 75%, 50%);
