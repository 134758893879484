@use 'variables';
@use '_print';
@use '_fonts';

@tailwind base;
@tailwind components;
@tailwind utilities;

// Include functions first
@import "../../node_modules/bootstrap/scss/functions";

// Customize some defaults
$body-color: #333;
$body-bg: white; // This variable is used to change the background colour of bootstrap input fields
$dark: variables.$colour-primary-dark;
$border-radius: .4rem;
$success: variables.$colour-firesmart-green;
$font-family-base: 'NeuzeitGro', sans-serif;

// Required
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/variables-dark";
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";

@import "../../node_modules/bootstrap/scss/type";
// @import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
// @import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
// @import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar"; // Requires nav
// @import "../../node_modules/bootstrap/scss/card";
// @import "../../node_modules/bootstrap/scss/breadcrumb";
// @import "../../node_modules/bootstrap/scss/accordion";
// @import "../../node_modules/bootstrap/scss/pagination";
// @import "../../node_modules/bootstrap/scss/badge";
// @import "../../node_modules/bootstrap/scss/alert";
// @import "../../node_modules/bootstrap/scss/progress";
// @import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
// @import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/modal"; // Requires transitions
// @import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";
// @import "../../node_modules/bootstrap/scss/carousel";
// @import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/offcanvas"; // Requires transitions
// @import "../../node_modules/bootstrap/scss/placeholders";

// Helpers
// @import "../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../node_modules/bootstrap/scss/utilities/api";


:root {
  --primary: #EFCA00;
  --offWhite: #F8FAFE;
  --slate: #212A34;
  --gray-100: #EEF1F8;
  --gray-200: #E4E8F3;
  --gray-300: #D1D9EC;
  --gray-600: #616569;
  --gray-800: #808589;
  --red-100: #ffe5e5;
  --red-200: #fbbaba;
  --red-300: #e96464;
  --red-400: #e03939;
  --red-500: #d70e0e;
  --red-600: #ad0b0b;
  --red-700: #830808;
  --red-800: #590505;
  --red-900: #2f0202;

  --border: #D4D9E5;
  --radius: 8px;
}

#assessments-table {
  th:nth-child(2){
    min-width:183px;
  }
  > thead > tr > th:nth-child(1) > div > div > div > button > svg {
  display: none;
  }
}

.fs-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
  @media (min-width: 768px) {
      padding-right: 30px;
      padding-left: 30px;
  }
  @media (min-width: 1200px) {
    padding-right: 80px;
    padding-left: 80px;
}
}

body {
  @extend .neuzeit-gro;
  background-color: variables.$colour-light-bluewhite;
  color: variables.$colour-primary-dark;
  padding-top: 125px;
  box-sizing: border-box;
  //height: 100%;
}

main {
  min-height:72vh;
}

h1, h2, h3, h4, h5, h6 {
  font-weight:bold;
}


/* Style a elements to be blue */
a {
  color: variables.$colour-primary-dark;
  text-decoration: none;

  &.active {    
    font-weight: 700;
  }

  &:hover {
    color: variables.$colour-firesmart-yellow;
  }
}

.header {
  // background-color: variables.$colour-primary-dark;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 100;

}

.nav-link{
  &.active {
    background-color: var(--gray-100);
  }
  &[aria-current="page"]{
    background-color: var(--gray-100);
  }
}

.userProfileHeader {
  display: flex;

  label {
    color: white;
  }
  .dropdown-menu{
    background-color: var(--offWhite);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
   
    .dropdown-item{
     &:hover{
      background-color: var(--gray-100);
     }
    }
  }
  .userProfileHeaderButton {
    background-color: variables.$colour-firesmart-yellow;
    border-radius: 28px;
    margin-top: 5px;
    text-align: center;
    color: #212a34;
    font-size: 1rem;
    line-height: 1.5;
    border: 1px solid transparent;
    font-weight: 600;

    &:hover {
      background-color: variables.$colour-light-bluewhite;
      color: variables.$colour-primary-dark;
      cursor: pointer;
    }
   
  }

  input {
    display: flex;
    //margin-left: auto;
  }
}



.course-listing {
  position: relative;
  // background-color: white;
  // border: 2px solid darken(variables.$colour-light-bluewhite, 5%);

  .course-listing-content {
    padding: 25px;
    border-top: 0px;
    margin-top: -5px;
  }

  &>img {
    margin: -2px;
  }
}

.landing-container {
  background-color: white;
  border: 2px solid darken(variables.$colour-light-bluewhite, 5%);
  border-radius: 2px;

  h4 {
    // margin: -2px -2px 8px -2px;
    // background-color: variables.$colour-primary-dark;
    // color: white;
    // padding: 15px;
    // border-radius: 2px;
  }
}

// TODO: If we want to have something above this on the landing page or limit the size then we'll 
//want to set the max-height here
.landing-container-content {
  //max-height: 60vh;
  overflow-y: auto;
}

.certification-listing {
  position: relative;
  background-color: variables.$colour-firesmart-yellow;
  border-radius: 2px;

  .certification-listing-content {
    padding: 25px;
    border-top: 0px;
    margin-top: -5px;
  }

  max-width: 400px;
  margin-bottom: 20px;
}

#course-listing-container {
  clear: both;
}

.btn-standard {
  background-color: variables.$colour-firesmart-yellow;
  border-radius: 5px;
  padding: 8px 15px 7px 15px;
  text-align: center;
  color: #212a34;
  font-size: 1rem;
  line-height: 1.5;
  border: 1px solid transparent;
  font-weight: 700;

  &:hover {
    background-color: #212a34;
    color: variables.$colour-firesmart-yellow;
    border-color: transparent;
    cursor: pointer;
  }

  &.btn-black{
    background-color: var(--slate);
    color: var(--offWhite);
    &:hover{
      background-color: var(--gray-300);
      color: var(--slate);
    }
  }

  &.btn-outline {
    background-color: transparent;
    border: 1px solid var(--slate);
    &:hover{
      background-color: var(--slate);
      color: var(--offWhite);
    }
  }

  &.btn-cancel {
    background-color: transparent;
    color: var(--red-600);
    border: 1px solid var(--red-600);
    &:hover{
      background-color: var(--red-600);
      color: white;
    }
  }

  &.danger {
    background-color: transparent;
    color: red;
    border: 1px solid red;

    &:hover {
      background-color: red;
      color: white;
      
    }
  }

  &.success {
    background-color: variables.$colour-firesmart-green;
    color: white;

    &:hover {
      background-color: white;
      color: variables.$colour-firesmart-green;
      border: 1px solid variables.$colour-firesmart-green;
    }
  }

  &.secondary {
    background-color: transparent;
    color: variables.$colour-primary-dark;
    border: 1px solid variables.$colour-primary-dark;

    &:hover {
      background-color: variables.$colour-primary-dark;
      color: white;
      border: 1px solid variables.$colour-primary-dark;
    }
  }
}

.no-margin-top {
  margin-top: 0px;
}

.no-margin-bottom {
  margin-bottom: 0px;
}




.small-header {
  width: 50%;
  display: inline-block;
}

.float-right {
  float: right;
}

.module-listing {
  list-style-type: none;
  padding-left: 0px;

  li {

    &:not(:last-of-type) {
      border-bottom: 1px solid black;
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
  }

  span {
    font-size: 24px;
    font-weight: 600;

    &:hover {
      color: variables.$colour-firesmart-yellow;
      cursor: pointer;
      //color: lighten(variables.$colour-primary-dark, 20%);
    }
  }
}

.center-div {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.center-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.inline {
  display: inline-block;
}

.flex {
  display: flex;
  flex-wrap: wrap;

  &.justify-center {
    justify-content: center;
  }

  &.justify-between {
    justify-content: space-between;
  }

  &.gap-100 {
    gap: 115px;
  }

  &.col-gap-20 {
    column-gap: 20px;
  }

  &.align-items-center {
    align-items: center;
  }

  &.align-items-flex-end {
    align-items: flex-end;
  }

  &.align-items-stretch {
    align-items: stretch;
  }
}

.flex-item-1 {
  flex-grow: 1;
}

.flex-item-2 {
  flex-grow: 2;
}

.page-preview {
  border: 2px solid darken(variables.$colour-light-bluewhite, 5%);
  padding: 30px;
  background-color: white;

  .page-content {
    text-align: left;
    height: 50vh;
    white-space: pre-wrap;
    overflow: auto;

    &.with-media {
      text-align: center;
    }

    video {
      max-height: 40vh;
    }
  }
}


footer {
  //padding: 20px 120px;
  padding-left: 10%;

  button.btn-standard {
    padding: 10px 20px;
    margin-right: 25px;
    font-size: 1.2rem;
  }
}

// .dt-scroll-head {
//   background-color: variables.$colour-primary-dark;
// }

.table { 
  background-color: var(--offWhite); border-collapse: separate; border-spacing: 0px;
  .dt-column-order{
    &:before, &:after {
      font-size: 0.6em !important;
    }
  }
  th,td{
    background-color: transparent!important;
    max-width:150px;
    text-align: left !important;
  }
  thead {
  
   th{
    font-weight:700; font-size:14px; border:1px solid var(--border); padding-top:10px;
    &:first-child {
     border-top-left-radius:8px; 
    }
    &:last-child {
      border-top-right-radius:8px; 
    }
    &:not(:last-child) {
      border-right: none;
    }     
    &:not(:first-child) {
      border-left: none;
    }
   }
  }
  tbody tr {
    transition: all 0.3s ease-in-out;
    
    &:first-child {
      td{
        border-top: none;
      }
    }
    &:last-child {
      td{
        border-bottom: 1px solid var(--border) !important;
        &:first-child {
          border-bottom-left-radius: 8px;
        }
        &:last-child {
          border-bottom-right-radius: 8px;
        }
        
      }
    }
    
    td{
      border:1px solid var(--border); border-bottom:none; 
      &:not(:last-child) {
        border-right: none;
      }
      &:not(:first-child) {
        border-left: none;
      }
    }
    &:hover {
      cursor: pointer; background-color:var(--gray-200) !important; transition: all 0.3s ease-in-out;
    }
  }
}

.fs-badge {
  background-color: variables.$colour-firesmart-yellow;
  //border-radius: 28px;
  border-radius: 4px;
  padding: 8px 13px 5px 13px;
  text-align: center;
  color: #212a34;
  font-size: 1rem;
  line-height: 1;
  border: 1px solid transparent;
  font-weight: 600;
  text-transform: uppercase;
  font-size:14px;

  &.large {
    font-size: 1.5rem;
  }

  &.fs-badge-success {
    background-color: variables.$colour-firesmart-green;
    color: white;
  }

  &.fs-badge-dark {
    background-color: variables.$colour-primary-dark;
    color: white;
  }

  &.fs-badge-danger {
    background-color: red;
    color: white;
  }

  &:empty {
    display: none;
  }
}

.font-weight-bold {
  font-weight: 600;
}

// .form-group {
//   margin-top: 10px;
// }

.form-control{
  padding: 10px 10px 7px 10px;
  flex-grow: 1;
  line-height: 1 !important;
}


.form-container {
  max-width: 1250px;
  padding: 0px 20px 10px 20px;
  border: 1px solid var(--border);
  border-radius: var(--radius);
  h4 {

    padding-top: 15px;    
  }
  .row{
    margin-bottom: 15px;
  }

  input {
    //border: 2px solid darken(variables.$colour-light-bluewhite, 5%);
    border-radius: 5px;

    &:focus {
      outline: none;
      border: 1px solid variables.$colour-firesmart-yellow;
    }
  }

  .input-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 2px;
    margin-bottom: 10px;
    column-gap: 10px;

    input {
      flex-grow: 1;
      padding: 10px 10px 7px 10px;
    }

    select {
      flex-grow: 1;
      padding: 10px;
      border: 2px solid darken(variables.$colour-light-bluewhite, 5%);
      border-radius: 5px;
      background-color: white;
      padding: 10px 10px 4px 10px;
      line-height: 1 !important;
      &:focus {
        outline: none;
        border: 2px solid variables.$colour-firesmart-yellow;
      }
    }
  }
}

//This is to prevent the lastpass div from breaking the layout for gaps
[data-lastpass-icon-root] {
  display: none !important;
}

label.required {
  &::after {
    content: "*";
    color: red;
  }
}

::placeholder {
  color: variables.$colour-primary-dark;
  opacity: 0.4;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-top-20 {
  margin-top: 20px;
}

.clickable {
  cursor: pointer;
}

.no-hover {
  &:hover {
    cursor: default !important;
    color: initial !important;
  }
}

.top-right {
  position: absolute;
  top: 8px;
  right: 8px;
}

.text-colour-green {
  color: variables.$colour-firesmart-green;
}

.pad-10 {
  padding: 10px;
}

.pad-5 {
  padding: 5px;
}

.relative {
  position: relative;
}

.card-img-top {
  width: 100%;
  height: 10vw;
  object-fit: cover;
}

.quiz-question {
  ul {
    list-style-type: none;
    padding-left: 0px;

    li {
      padding: 10px;
      border: 2px solid darken(variables.$colour-light-bluewhite, 5%);
      border-radius: 5px;
      margin-bottom: 5px;

      label {
        margin-left: 5px;

        &:hover {
          background-color: variables.$colour-light-bluewhite;
          cursor: pointer;
        }
      }

      &:hover {
        background-color: variables.$colour-light-bluewhite;
        cursor: pointer;
      }
    }
  }
}

.audit-options-group {
  border-radius: 8px;
  padding: 20px 20px 20px 20px
}

.audit-question-container {
  // h4 {
  //   font-weight: 500;
  //   margin: -20px;
  //   margin-bottom: 20px;
  //   padding: 20px;
  //   background-color: variables.$colour-dark-grey;
  //   color: white;
  //   border-radius: 5px 5px 0px 0px;
  // }

  // border-radius: 5px;
  // background-color: variables.$colour-lighter-bluewhite;
  // box-shadow: 0px 3px 6px hsla(0, 0%, 0%, 0.205),
  // 0px 1px 4px hsla(0, 0%, 0%, .1);
  // margin-bottom: 20px;
  // padding: 20px;
}
.pdf-image-container{

  display:grid;
  grid-template-columns: repeat(4, 1fr); // Create a 4-column grid
}

.audit-image-preview {
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0px 3px 6px hsla(0, 0%, 0%, 0.205),
    0px 1px 4px hsla(0, 0%, 0%, .1);

  position: relative;

  img{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  &:hover {
    cursor: zoom-in;
  }

  .remove-image-icon {
    position: absolute;
    top: -10px;
    right: -10px;

    &:hover {
      cursor: pointer;
      color: variables.$colour-red;
    }
  }
}

.audit-image-full {
  max-width: 800px;
  padding-left: 0px;
  padding-right: 0px;
  box-shadow: 0px 3px 6px hsla(0, 0%, 0%, 0.205),
    0px 1px 4px hsla(0, 0%, 0%, .1);
}

.audit-splashscreen-preview {
  height: 150px;
  width: 150px;
  background-size: cover;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0px 3px 6px hsla(0, 0%, 0%, 0.205),
    0px 1px 4px hsla(0, 0%, 0%, .1);

  position: relative;

  &:hover {
    cursor: pointer;
  }
}


#fullpage {
  display: none;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: contain;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-color: rgba(0, 0, 0, 0.8);
  cursor: zoom-out;
}

#inspection-minimap {
  position: fixed;

  .change-item {
    border: 2px solid darken(variables.$colour-light-bluewhite, 5%);
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 2px;

    &:hover {
      background-color: variables.$colour-lighter-bluewhite;
      cursor: pointer;
      a {
        color: variables.$colour-firesmart-yellow !important;
      }
    }
  }
}

.confirm-dialog {
  background-color: white;
  border-radius: 5px;
  padding: 30px;
  box-shadow: 0px 3px 6px hsla(0, 0%, 0%, 0.205),
    0px 1px 4px hsla(0, 0%, 0%, .1);
  overflow: hidden;
}

.react-confirm-alert-overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.my-inspections-landing {
  margin-top: -10px;

  .count-container {
   

    &:hover {
      cursor: pointer;
      color: variables.$colour-firesmart-yellow;
    }

      .title {
        @extend .neuzeit-gro-semi-bold;
        text-align: center;
        font-size: x-large;
      }
    
      .count {
        @extend .neuzeit-gro-bold;
        text-align: center;
        font-size: xx-large;
      }
  }
}
.pagination
.page-item{
  &.active{

    .page-link{
      background-color: var(--black); border-color: var(--black);
    }
  }

}

.react-datepicker-wrapper{
  width: 100%; display: block;
  .react-datepicker__input-container{
    width: 100%;
    input{
      width: 100%;
    }
  }
}


.terms-modal {
  .modal-dialog {
    width: 95vw !important;
    max-width: 95vw !important;
    @media (min-width: 768px) {
          width: 60vw !important;
          max-width: 1200px !important;
    }
  }
}

.underline-links{
  a {
    text-decoration: underline !important;
  }
}