.annotator_container {
  gap: 10px;
  padding: 10px;
  width: 80%;
  display: flex;
  user-select: none;
  height: 80%;
  overflow: hidden;
  background-color: #F7FAFE;
  border: 1px solid #dee2e6;
  border-radius: 10px;
}

.annotator_container section {
  border-radius: 7px;
  background: #fff;
}

.annotator_tools-board {
  width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 22px 0;
  border: 1px solid #dee2e6;
}

.annotator_tools-board .annotator_row {
  margin-bottom: 20px;
  width: 100%;
}

.annotator_row .annotator_title {
  color: #333;
  font-size: 1rem;
}

.annotator_row .annotator_options {
  list-style: none;
  margin: 10px 0 0 0;

}

ul.annotator_options {
  padding-left: 0px;
}

.annotator_row .annotator_options .annotator_option {
  display: flex;
  user-select: none;
  cursor: pointer;
  align-items: center;
  margin-bottom: 10px;
}

.annotator_row .annotator_options .annotator_option .annotator_icon {
  font-size: 1.3rem;
  padding-left: 3px;
}

.annotator_row .annotator_options .annotator_option .annotator_name {
  padding-left: 8px;
}

.annotator_option :where(span, label) {
  color: #5A6168;
  cursor: pointer;
}

.annotator_option:is(:hover, .active) :where(span, label) {
  color: #4A98F7;
}

.annotator_option input[type="checkbox"] {
  height: 15px;
  width: 15px;
  margin: 0 10px 0 5px;
  cursor: pointer;
}

input[type="checkbox"]:checked~label {
  color: #4A98F7;
}

.annotator_option #brush-size-slider {
  width: 100%;
  height: 5px;
  margin-top: 10px;
}

.annotator_tools-board .annotator_colors-tool {
  margin-top: 25px;
}

.annotator_tools-board .annotator_colors {
  display: flex;
  justify-content: space-between;
}

.annotator_colors .annotator_option {
  position: relative;
  height: 25px;
  width: 25px;
  margin-top: 3px;
  border-radius: 50%;
}

.annotator_colors .annotator_option:nth-child(1) {
  background-color: #fff;
  border: 1px solid #bfbfbf;
}

.annotator_colors .annotator_option:nth-child(2) {
  background-color: #000;
}

.annotator_colors .annotator_option:nth-child(3) {
  background-color: #E02020;
}

.annotator_colors .annotator_option:nth-child(4) {
  background-color: #6DD400;
}

.annotator_colors .annotator_option:nth-child(5) {
  background-color: #f34f4f;
  height: 23px;
  width: 23px;
  background-image: linear-gradient(#3c3cff, #f34f4f);
}

.annotator_colors .annotator_option.active:nth-child(5) {
  background-image: none;
}

.annotator_colors .annotator_option.selected:before {
  content: "";
  top: 50%;
  left: 50%;
  height: 12px;
  width: 12px;
  background: inherit;
  position: absolute;
  border: 2px solid #fff;
  border-radius: inherit;
  transform: translate(-50%, -50%);
}

.annotator_colors .annotator_option:first-child.selected:before {
  border-color: #ccc;
}

.annotator_colors #color-picker {
  opacity: 0;
  cursor: pointer;
}

.annotator_buttons .annotator_title {
  display: none;
}

.annotator_buttons button {
  width: 100%;
  color: #fff;
  border: none;
  outline: none;
  padding: 11px 0;
  font-size: 0.9rem;
  background: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.3s ease;
}

.annotator_buttons button .annotator_icon {
  color: #fff;
  font-size: 1.2rem;
  transition: 0.2s ease;
}

.annotator_buttons .annotator_clear-canvas {
  color: #6C757D;
  border: 1px solid #6C757D;
}

.annotator_buttons .annotator_clear-canvas .annotator_icon {
  color: #6C757D;
}

.annotator_buttons .annotator_clear-canvas:hover {
  color: #fff;
  background: #6C757D;
}

.annotator_buttons .annotator_clear-canvas:hover .annotator_icon {
  color: #fff;
}

.annotator_buttons .annotator_save-img {
  background: #4A98F7;
  border: 1px solid #4A98F7;
}

.annotator_buttons .annotator_save-img:hover {
  background: #2382f6;
}

.annotator_drawing-board {
  flex: 1;
  overflow: hidden;
  width: 570px;
  border: 1px solid #dee2e6;
}

.annotator_drawing-board canvas {
  width: 100%;
  height: 100%;
  border-radius: 7px;
}

@media (min-width: 481px) and (max-width: 1023px) and (orientation: landscape) {
  .annotator_container {
    gap: 5px;
    padding: 5px;
  }

  .annotator_tools-board {
    width: 190px;
    max-height: calc(var(--doc-height) - 10px);
    height: 100%;
    overflow-y: auto;
    padding: 8px 13px 0;
  }

  .annotator_row {
    margin-bottom: 5px;
  }

  .annotator_row :is(.annotator_title, .annotator_name) {
    font-size: 0.9rem;
  }

  .annotator_row:not(.annotator_actions-tool) .annotator_option .annotator_name {
    display: none;
  }

  .annotator_row .annotator_options {
    display: flex;
    justify-content: space-between;
  }

  .annotator_row .annotator_actions-tool .annotator_options {
    gap: 30px;
    justify-content: start;
  }

  .annotator_row .annotator_options .annotator_option {
    margin-bottom: 5px;
  }

  .annotator_row .annotator_options {
    margin: 8px 0 0 4px;
  }

  .annotator_colors .annotator_option {
    height: 18px;
    width: 18px;
  }

  .annotator_row .annotator_options .annotator_option .annotator_icon {
    font-size: 1.25rem;
  }

  .annotator_option :where(.annotator_title, label) {
    display: none;
  }

  .annotator_shapes-tool .annotator_options {
    display: flex;
    justify-content: space-between;
  }

  .annotator_options-tool .annotator_options {
    display: flex;
    width: 100%;
  }

  .annotator_options-tool .annotator_option {
    width: calc(100% / 2);
  }

  .annotator_options-tool .annotator_option:last-child {
    width: 100%;
    display: block;
  }

  .annotator_tools-board .annotator_colors-tool {
    margin-top: 0;
  }

  .annotator_colors .annotator_option.selected:before {
    height: 10px;
    width: 10px;
  }

  .annotator_joined-tools {
    display: flex;
    gap: 20px;
    margin-top: 10px;
    justify-content: space-between;
  }

  .annotator_colors-tool .annotator_option:nth-child(1),
  .annotator_colors-tool .annotator_option:nth-child(3),
  .annotator_colors-tool .annotator_option:nth-child(3) {
    display: none;
  }

  .annotator_actions-tool .annotator_option:is(:hover, .active) :where(span, label) {
    color: #5A6168;
  }

  .annotator_actions-tool .annotator_option .annotator_name {
    display: none;
  }

  .annotator_buttons {
    display: flex;
    gap: 15px;
    margin-top: 8px;
  }

  .annotator_buttons button {
    padding: 9px 0;
  }

  .annotator_buttons button .annotator_name {
    display: none;
  }

  .annotator_drawing-board {
    max-height: calc(var(--doc-height) - 10px);
    height: 100%;
  }
}

@media screen and (max-width: 540px) and (orientation:portrait) {
  .annotator_container {
    gap: 5px;
    padding: 5px;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .annotator_tools-board {
    width: 100%;
    order: 2;
    overflow-y: auto;
    max-height: calc(var(--doc-height) / 2 - 10px);
    display: block;
    padding: 12px;
  }

  .annotator_row {
    margin-bottom: 10px;
  }

  .annotator_row:not(.annotator_actions-tool) .annotator_option .annotator_name {
    display: none;
  }

  .annotator_row .annotator_options {
    display: flex;
    
  }

  .annotator_actions-tool .annotator_options {
    gap: 30px;
    justify-content: start;
  }

  .annotator_row .annotator_options .annotator_option {
    margin-bottom: 5px;
  }

  .annotator_option input[type="checkbox"] {
    height: 17px;
    width: 17px;
  }

  .annotator_tools-board .annotator_colors-tool {
    margin: 0;
  }

  .annotator_tools-board .annotator_colors .annotator_option {
    margin-bottom: 20px;
  }

  .annotator_joined-tools {
    display: flex;
    gap: 30px;
    margin-top: 15px;
    justify-content: space-between;
  }

  .annotator_actions-tool .annotator_option:is(:hover, .active) :where(span, label) {
    color: #5A6168;
  }

  .annotator_drawing-board {
    order: 1;
    max-height: calc(var(--doc-height) / 2 - 10px);
    height: 100%;
    width: 100%;
  }
}

.annotator_upload-img {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 11px 0;
  color: #fff;
  background: #4A98F7;
  border-radius: 5px;
  cursor: pointer;
}

.annotator_upload-img:hover {
  background: #2f80f3;
}

.annotator_upload-img.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.annotator_options.horizontal {
  display: flex;
  flex-direction: row;
  gap: 10px;
}