@use 'variables';

@media print {
  .no-print {
    display: none;
  }

  body {
    padding-top: 0px !important;
  }

  .fs-container {
    padding: 0px !important;
    margin: 0px !important;
  }

}
